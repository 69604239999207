:root {
  --form-max-width: 600px;
  --form-padding: 20px;
  --form-background-color: #f9f9f9;
  --form-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --form-border-radius: 8px;
  --form-margin-top: 6%;
  
  --heading-color: #333;
  --label-margin-bottom: 5px;
  --input-padding: 10px;
  --input-border: 1px solid #ccc;
  --input-border-radius: 4px;
  
  --button-bg-start: #4b6cb7;
  --button-bg-end: #182848;
  --button-bg-hover-start: #2c3e50;
  --button-bg-hover-end: #4b6cb7;
  --button-text-color: white;
  --button-font-size: 16px;
  --button-transition: background 0.3s ease;
}

.contact-form-container {
  max-width: var(--form-max-width);
  margin: 0 auto;
  padding: var(--form-padding);
  background-color: var(--form-background-color);
  box-shadow: var(--form-box-shadow);
  border-radius: var(--form-border-radius);
  margin-top: var(--form-margin-top);
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--heading-color);
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-group label {
  display: block;
  margin-bottom: var(--label-margin-bottom);
  font-weight: bold;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
  width: 100%;
  padding: var(--input-padding);
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
}

.contact-form .form-group textarea {
  resize: vertical;
}

.contact-form .send-button {
  display: block;
  width: 100%;
  padding: 10px;
  background: linear-gradient(to right, var(--button-bg-start), var(--button-bg-end)); /* Gradient from blue to darker blue */
  color: var(--button-text-color);
  border: none;
  border-radius: var(--input-border-radius);
  cursor: pointer;
  font-size: var(--button-font-size);
  transition: var(--button-transition);
}

.contact-form .send-button:hover {
  background: linear-gradient(to right, var(--button-bg-hover-start), var(--button-bg-hover-end)); /* Gradient on hover */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  :root {
    --form-max-width: 90%;
    --form-padding: 15px;
  }
  
  .contact-form-container {
    margin-top: 4%;
  }
}
