:root {
  --container-max-width: 600px;
  --container-padding: 20px;
  --container-background-color: #f9f9f9;
  --container-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --container-border-radius: 8px;
  --container-text-align: center;
  --container-margin-top: 6%;

  --heading-color: #333;
  --paragraph-color: #666;
  --paragraph-margin-bottom: 30px;

  --label-margin-bottom: 5px;
  --input-padding: 10px;
  --input-border: 1px solid #ccc;
  --input-border-radius: 4px;

  --button-bg-start: #4CAF50;
  --button-bg-end: #008CBA;
  --button-bg-hover-start: #45a049;
  --button-bg-hover-end: #0076a3;
  --button-text-color: white;
  --button-font-size: 16px;
  --button-transition: background 0.3s ease;
}

.feedback-container {
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: var(--container-padding);
  background-color: var(--container-background-color);
  box-shadow: var(--container-box-shadow);
  border-radius: var(--container-border-radius);
  text-align: var(--container-text-align);
  margin-top: var(--container-margin-top);
}

.feedback-container h2 {
  margin-bottom: 20px;
  color: var(--heading-color);
}

.feedback-container p {
  color: var(--paragraph-color);
  margin-bottom: var(--paragraph-margin-bottom);
}

.feedback-form .form-group {
  margin-bottom: 15px;
}

.feedback-form .form-group label {
  display: block;
  margin-bottom: var(--label-margin-bottom);
  font-weight: bold;
}

.feedback-form .form-group input,
.feedback-form .form-group textarea {
  width: 100%;
  padding: var(--input-padding);
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
}

.feedback-form .form-group textarea {
  resize: vertical;
}

.feedback-form .send-button {
  display: block;
  width: 100%;
  padding: 10px;
  background: linear-gradient(to right, var(--button-bg-start), var(--button-bg-end)); /* Gradient from green to blue */
  color: var(--button-text-color);
  border: none;
  border-radius: var(--input-border-radius);
  cursor: pointer;
  font-size: var(--button-font-size);
  transition: var(--button-transition);
}

.feedback-form .send-button:hover {
  background: linear-gradient(to right, var(--button-bg-hover-start), var(--button-bg-hover-end)); /* Gradient on hover */
}

/* Responsive design for smaller screens */
@media (max-width: 1024px) {
  :root {
    --container-max-width: 80%;
    --container-padding: 15px;
    --container-margin-top: 4%;
  }
}

@media (max-width: 768px) {
  :root {
    --container-max-width: 90%;
    --container-padding: 10px;
    --container-margin-top: 3%;
  }
}

@media (max-width: 480px) {
  :root {
    --container-max-width: 95%;
    --container-padding: 8px;
    --container-margin-top: 2%;
    --button-font-size: 14px;
  }

  .feedback-container h2 {
    font-size: 1.5em;
  }

  .feedback-container p {
    font-size: 0.9em;
  }
}
