body {
  overflow: hidden;
  margin: 0;
}

.chevronButton {
  position: absolute;
  left: 95%;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: relative;
  left: 0;
  border-radius: 4px;
  max-width: 60%;
  min-width: 25%;
  margin-left: 1.5%;
  margin-bottom: 1%;
}

.inputContainer {
  font-family: monospace;
  z-index: 1;
  align-items: flex-start;
}

.inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.suggestionListItem {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid gray;
  height: 30px;
  justify-content: center;
  text-align: left;
  padding: 10px;
}

.suggestionText {
  justify-content: center;
  align-items: center;
  font-size: medium;
  font-weight: 700;
}

.map-container {
  height: 95vh;
}

.suggestionsContainer {
  color: black;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 90px;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.outerInputContainer {
  display: flex;
  flex-direction: row;
}

.locationReportContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  font-family: Arial, sans-serif;
  border-radius: 10px 10px 0 0;
  transition: transform 0.3s ease-in-out;
}

.locationReportContainer.minimized {
  transform: translateY(100%);
}

.title {
  margin: 0 0 10px 0;
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.info-row {
  display: flex;
}

.label {
  font-weight: bold;
}

.value {
  margin-left: 5px;
}

.value-red {
  margin-left: 5px;
  color: red;
  font-weight: bold;
}

.value-yellow {
  margin-left: 5px;
  color: #ffcc00;
  font-weight: bold;
}

.value-green {
  margin-left: 5px;
  color: #339900;
  font-weight: bold;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.next-route-button {
  background-color: #02BDB7;
  border: none;
  color: white;
  padding: 7px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.next-route-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.next-route-button:active {
  background-color: #3e8e41;
  transform: scale(0.95);
}

/* Safety Report Styles */
.report-container {
  position: relative;
}

.locationReportContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  font-family: Arial, sans-serif;
  border-radius: 10px 10px 0 0;
  transition: transform 0.3s ease-in-out;
}

.locationReportContainer.minimized {
  transform: translate(-50%, 100%);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.chevronButton {
  position: absolute;
  left: 95%;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.label {
  font-weight: bold;
  color: #555;
}

.value {
  color: #000;
}

.value-red {
  color: red;
  font-weight: bold;
}

.value-yellow {
  color: orange;
  font-weight: bold;
}

.value-green {
  color: green;
  font-weight: bold;
}

.location-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}

/* Multi-route Analysis Styles */
.report-container {
  position: absolute;
  height: 100vh;
  width: 30%;
  right: 0%;
}

.locationReportContainer {
  position: absolute;
  top: 0%;
  right: 0%;
  left: 54%;
  width: 25vw;
  overflow-y: auto;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  font-family: Arial, sans-serif;
  border-radius: 0px;
  transition: transform 0.3s ease-in-out;
}

.locationReportContainer.minimized {
  transform: translateY(-100%);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;
}

.title {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.chevronButton {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: small;
}

.label {
  font-weight: bold;
}

.value {
  color: #000;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
  }

  .locationReportContainer {
    position: fixed; 
    bottom: 0;
    width: 60%;
    height: 50%;
    left: 0;
    margin-top: 49%;
    transform: translateY(0%);
    border-radius: 10px 10px 0 0;
    overflow-y: auto; 
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.90);
    padding: 3%; 
  }

  .chevronButton {
    position: fixed;
    top: 10px;
    right: 10px;
    background: #007bff;
    color: white;
    z-index: 1001; 
  }

  .info-row {
    padding: 5px;
    margin: 0;
  }

  .label {
    margin-right: 10px; 
  }

  .value {
    margin: 0;
  }
}
