.container{
    background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}
.investor-message {
  background-color: #f7f7f7;
  padding: 1%;
  margin: 1%;
  border-radius: 9px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 0.8em;
  color: #333;
  width: 60%;
  max-width: 60%;
  margin-bottom: 3%;
}

.Homepage-Title {
    background: linear-gradient(45deg, #6a5acd, #ff0000); /* Gradient from bluish-purple (#6a5acd) to red (#ff0000) at 45 degrees */
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text; /* For other browsers */
              color: transparent; /* For other browsers */
              margin-bottom: -30px;
              margin-top: -120px;
  }
  .slogan {
    max-width: 1000px;
    color: black;

  }
  
  .button-container {
    flex-direction: row;
    max-width: "100%";
    width: 100%;
    align-self: "center";
    min-height: 50px;
    background-color: "blue";
    justify-content: center;
    /* justify-content: space-between; */
  }
  .button {
    /* max-width: 80px; */
    /* border-radius: 100px; */
    margin-left: 10px;
    align-items: center;
  justify-content: center;
  }