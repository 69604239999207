.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    background-color: #fff;
    padding: 15px 30px;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box; 
}

.navbar-title {
    font-size: 1.5rem;
    font-weight: bold;
    background: linear-gradient(45deg, #6a5acd, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 10px; 
    line-height: 1;
}

.hamburger {
    display: none; 
    font-size: 1.8rem;
    cursor: pointer;
    margin-left: auto; 
    margin-right: 10px; 
    line-height: 1; 
}

.navbar-links {
    list-style-type: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    margin: 0;
}

.navbar-links a {
    text-decoration: none;
    color: #444;
    font-weight: 500;
    transition: color 0.3s ease;
}


.navbar-links a:hover {
    background: linear-gradient(45deg, #ff0000, #6a5acd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        align-items: flex-start;
    }

    .hamburger {
        display: block;
        margin-left: 2%;
        margin-right: 2%;
        color: #6c6c6c;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        margin-top: 10px;
        background-color: #fff;
        padding: 10px 0;
        border-top: 1px solid #eee;
    }

    .navbar-links.open {
        display: flex;
    }

    .navbar-links a {
        font-size: 1.1rem;
        padding: 5px 0;
    }
}
