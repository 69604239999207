.App {
  text-align: center;
  /* background-color: black; */
  justify-content: center;
  align-items: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Homepage-Title {
  background: linear-gradient(45deg, #6a5acd, #ff0000); /* Gradient from bluish-purple (#6a5acd) to red (#ff0000) at 45 degrees */
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text; /* For other browsers */
            color: transparent; /* For other browsers */
            margin-bottom: -20px;
}
.slogan {
  max-width: "60%";
}

.button-container {
  flex-direction: row;
  max-width: "60%";
  align-self: "center";
  min-height: 50px;
  background-color: "blue";
  /* justify-content: space-between; */
}
.button {
  /* max-width: 80px; */
  /* border-radius: 100px; */
  margin-left: 10px;

}