/* Scrolling container for the text */
.scrolling-container {
    width: 100%;
    overflow: hidden;
    background-color: #1f1f1f; /* Dark background for contrast */
    border: 2px solid #ff4500; /* Bright border to highlight */
    white-space: nowrap;
    padding: 10px 0; /* Padding for better spacing */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  }
  
  /* Styling the scrolling text */
  .scrolling-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 70s linear infinite; /* Slower, smoother scroll */
  }
  .scrolling-text-faster {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 20s linear infinite; /* Slower, smoother scroll */
  }
  
  /* Styling each headline */
  .scrolling-text span {
    display: inline-block;
    padding: 0 2rem;
    font-size: 1.4rem; /* Larger text for visibility */
    color: #fff; /* White text color for contrast */
    font-weight: bold; /* Bold text for emphasis */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Subtle text shadow */
  }
  .scrolling-text-faster span {
    display: inline-block;
    padding: 0 2rem;
    font-size: 1.4rem; /* Larger text for visibility */
    color: #fff; /* White text color for contrast */
    font-weight: bold; /* Bold text for emphasis */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Subtle text shadow */
  }
  
  /* Keyframes for the scrolling animation */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  