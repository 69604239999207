/* Basic Reset */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
    color: black; /* Default text color */
    overflow-y: visible; /* Allow vertical scrolling */
  }
  
  /* Container Styling */
  .protest-screen {
    display: flex;
    flex-direction: column;
  }
  
  /* Header Styling */
  .header {
    background-color: #282c34;
    color: white;
    text-align: center;
    margin-bottom: 0%;
  }
  .h1 {
    text-align: center;
  }
  .region-selector {
    margin-bottom: 2%;
  }
  
  /* Label for the region selector */
  .region-selector label {
    color: #fff;
    font-size: 1rem;
    margin-right: 10px;
  }
  
  /* Dropdown styling for region selector */
  .region-selector select {
    padding: 8px 12px;
    font-size: 1rem;
    border: 2px solid #ff4500;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    outline: none;
  }
  
  .region-selector select:focus {
    border-color: #ff8500;
  }
  /* Main Content Styling */
  .content {
    flex: 1;
    padding: 1rem;
  }
  
  /* Search and Filter Section */
  .search-filter, .address-search {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
  
  .search-filter input, .search-filter select, .address-search input {
    margin-right: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex-grow: 1;
    max-width: 300px;
  }
  
  /* Map Container */
  .map-container {
    margin-bottom: 2rem;
    position: relative;
    /* width: "90%";
    height: 80vh;
    top: 10vh; */
  }
  
  .leaflet-container {
    width: 100%;
    height: 600px;
    border-radius: 8px;
  }
  
  /* Protest List */
  .protest-list {
    margin-bottom: 2rem;
  }
  
  .protest-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .protest-item:hover {
    background-color: #f0f0f0;
  }
  
  /* Protest Details */
  .protest-details {
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    z-index: 1000;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .protest-details button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #282c34;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .protest-details button:hover {
    background-color: #444;
  }
  
  /* Latest News and Contact Info */
  .latest-news, .contact-info {
    margin-top: 2rem;
  }
  
  /* General Advice Section */
  .advice-section {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 2rem;
  }
  
  /* Scroll to Top Button */
  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 0.5rem 1rem;
    background-color: #282c34;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    z-index: 1000;
  }
  
  .scroll-to-top:hover {
    background-color: #444;
  }
  
  /* Styling for Protest Circles */
  .leaflet-pane .leaflet-overlay-pane .leaflet-interactive {
    stroke: red;
    fill: red;
    fill-opacity: 0.4;
    stroke-opacity: 0.7;
  }
  
  /* Notification for Location Risk */
  .risk-notification {
    background-color: #ffeeee;
    border: 1px solid #ffcccc;
    color: #cc0000;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  